import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import TENANT from '../queries/tenant';
import USER from '../queries/user';
import UPDATE_TENANT from '../mutations/updateTenant';
import DELETE_TENANT from '../mutations/deleteTenant';
import Form from '../components/Form';
import Button from '../components/Button';
import validateForm from '../utils/validateForm';

const EditContainer = styled.div`
  margin: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 80%;
  min-width: 300px;
  height: 600px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px ${props => props.theme.black};
  background-color: ${props => props.theme.white};
  h2 {
    display: flex;
    justify-content: center;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: ${props => props.theme.black};
  }
  button {
    margin-top: 1em;
    cursor: pointer;
    min-width: 100%;
    color: ${props => props.theme.white};
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1em;
    text-align: center;
  }
  .cancel {
    background-color: ${props => props.theme.navItemColors};
  }
  .delete {
    background-color: ${props => props.theme.red};
  }
`;

const Edit = props => {
  const [updateEditState, setUpdateTenant] = useState({
    floor: '',
    name: '',
    suite: '',
  });

  const [errors, setErrors] = useState({
    floorError: '',
    nameError: '',
    suiteError: '',
  });

  const { data, loading, error } = useQuery(TENANT, {
    variables: { id: props.location.state.id },
  });

  useMemo(() => {
    if (!loading) {
      setUpdateTenant(data.tenant);
    }
  }, [data, loading]);

  const [updateTenant] = useMutation(UPDATE_TENANT, {
    refetchQueries: [{ query: TENANT, variables: { id: props.location.state.id } }],
  });

  const [deleteTenant] = useMutation(DELETE_TENANT, {
    refetchQueries: [{ query: USER, variables: { id: props.userId } }],
  });

  const handleChange = e => {
    const { name, value } = e.target;

    setUpdateTenant({ ...updateEditState, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const { floor, name, suite } = updateEditState;

    // check for floor errors
    if (floor.length === 0) {
      setErrors({ ...errors, nameError: 'Floor can not be empty!' });
    }

    if (floor.length > 3) {
      setErrors({ ...errors, floorError: 'Floor # cannot be more than 3 characters long!' });
    }

    // check for name errors
    if (name.length === 0) {
      setErrors({ ...errors, nameError: 'Name can not be empty!' });
    }

    if (name.length < 5) {
      setErrors({ ...errors, nameError: 'Name must be minimum 5 characters long!' });
    }

    // check for suite errors
    if (suite.length === 0) {
      setErrors({ ...errors, nameError: 'Suite can not be empty!' });
    }

    if (validateForm(errors)) {
      const response = await updateTenant({
        variables: {
          id: props.location.state.id,
          name: updateEditState.name,
          floor: updateEditState.floor,
          suite: updateEditState.suite,
        },
      });

      if (response) {
        if (window.confirm(`Tenant: ${response.data.updateTenant.name} has been updated`)) {
          props.history.push('/');
        }
      }
    }
  };

  const handleCancel = () => {
    props.history.push({
      pathname: '/',
    });
  };

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you would like to delete ${data.tenant.name}`)) {
      const response = await deleteTenant({
        variables: {
          id: data.tenant.id,
        },
      });

      if (response) {
        props.history.push('/');
      }
    }
  };

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <EditContainer>
      <FormEditContainer>
        <h2>Edit Tenant</h2>
        <Form method="post" onSubmit={handleSubmit}>
          <fieldset>
            <div className="floor">
              <label htmlFor="floor">
                Floor
                <input
                  type="text"
                  name="floor"
                  placeholder="floor"
                  value={updateEditState.floor}
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="name">
              <label htmlFor="name">
                Name
                <input
                  type="text"
                  name="name"
                  placeholder="name"
                  value={updateEditState.name}
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="suite">
              <label htmlFor="suite">
                Suite
                <input
                  type="text"
                  name="suite"
                  placeholder="suite"
                  value={updateEditState.suite}
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="submit">
              <Button type="submit" className="submit" title={loading ? 'Submitting' : 'Submit'} />
            </div>
          </fieldset>
        </Form>
        <button className="cancel" onClick={handleCancel}>
          Cancel
        </button>
        <button className="delete" onClick={handleDelete}>
          Delete
        </button>
      </FormEditContainer>
    </EditContainer>
  );
};

export default Edit;
