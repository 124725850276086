import React, { useState } from 'react';
import styled from 'styled-components';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import icon from '../assets/edit.png';
// import TableLoader from './TableLoader';

const TenantsContainer = styled.div`
  background-color: ${props => props.theme.white};
  border-radius: 1em;
  .button-container {
    width: 100%;
  }
  button {
    display: block;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 10px;
    width: 100%;
  }
`;

export const IconContainer = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const Tenants = ({ history, tenants, loading }) => {
  const [filtered, setFiltered] = useState([]);

  const route = id => {
    history.push({
      pathname: '/edit',
      state: { id },
    });
  };

  return (
    <TenantsContainer>
      <div className="button-container">
        <button onClick={() => setFiltered([])}>Clear Filters</button>
      </div>
      <ReactTable
        data={tenants}
        loading={loading}
        // LoadingComponent={TableLoader}
        showPagination={false}
        resizable={false}
        noDataText=" "
        filterable
        filtered={filtered}
        onFilteredChange={e => {
          setFiltered(e);
        }}
        pageSize={tenants.length}
        style={{
          height: '700px', // This will force the table body to overflow and scroll, since there is not enough room
        }}
        className={loading ? '-highlight' : '-striped -highlight'}
        defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
        defaultSorted={[
          {
            id: 'floor',
            desc: false,
          },
        ]}
        columns={[
          {
            columns: [
              {
                Header: 'FLOOR',
                id: 'floor',
                className: 'center-table-content',
                filterAll: true,
                accessor: d => (isNaN(d.floor) ? d.floor : Number(d.floor)),
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ['floor'] }),
              },
              {
                Header: 'TENANT NAME',
                id: 'name',
                className: 'center-table-content',
                filterAll: true,
                accessor: d => d.name,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
              },
              {
                Header: 'SUITE',
                id: 'suite',
                className: 'center-table-content',
                filterAll: true,
                accessor: d => d.suite,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ['suite'] }),
              },

              {
                Header: 'View',
                id: 'view-button',
                filterable: false,
                maxWidth: 60,
                className: 'center-table-content',
                Cell: props => (
                  <IconContainer onClick={() => route(props.original.id)}>
                    <Icon src={icon} alt="Edit" />
                  </IconContainer>
                ),
              },
            ],
          },
        ]}
      />
    </TenantsContainer>
  );
};

export default Tenants;
