import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import BUILDING from '../queries/building';

const Container = styled.div`
  padding: 2em;
`;

const DisplayContainer = styled.div`
  background-color: ${props => props.theme.white};
  border-radius: 1em;
  .button-container {
    width: 100%;
  }
  button {
    display: block;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 10px;
    width: 100%;
  }
`;

const Display = props => {
  const [filtered, setFiltered] = useState([]);
  const { data, loading, error } = useQuery(BUILDING, {
    variables: { id: process.env.REACT_APP_PARKER_ID },
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  return (
    <Container>
      <DisplayContainer>
        <div className="button-container">
          <button onClick={() => setFiltered([])}>Clear Filters</button>
        </div>
        <ReactTable
          data={data.building.tenants}
          loading={loading}
          // LoadingComponent={TableLoader}
          showPagination={false}
          resizable={false}
          noDataText=" "
          filterable
          filtered={filtered}
          onFilteredChange={e => {
            setFiltered(e);
          }}
          defaultPageSize={-1}
          style={{
            height: '725px', // This will force the table body to overflow and scroll, since there is not enough room
          }}
          className={loading ? '-highlight' : '-striped -highlight'}
          defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
          defaultSorted={[
            {
              id: 'floor',
              desc: false,
            },
          ]}
          columns={[
            {
              columns: [
                {
                  Header: 'FLOOR',
                  id: 'floor',
                  className: 'center-table-content',
                  filterAll: true,
                  accessor: d => (isNaN(d.floor) ? d.floor : Number(d.floor)),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ['floor'] }),
                },
                {
                  Header: 'TENANT NAME',
                  id: 'name',
                  className: 'center-table-content',
                  filterAll: true,
                  accessor: d => d.name,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ['name'] }),
                },
                {
                  Header: 'SUITE',
                  id: 'suite',
                  className: 'center-table-content',
                  filterAll: true,
                  accessor: d => d.suite,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ['suite'] }),
                },
              ],
            },
          ]}
        />
      </DisplayContainer>
    </Container>
  );
};

export default Display;
