import React from 'react';
import { Switch, Route, Redirect, withRouter, BrowserRouter as Router } from 'react-router-dom';
import Header from '../components/Header';
import Display from '../containers/Display';
import Login from '../containers/Login';
import Home from '../containers/Home';
import Edit from '../containers/Edit';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      userId: '',
    };
  }

  UNSAFE_componentWillMount() {
    const token = localStorage.getItem('lobby_token');
    const userId = localStorage.getItem('user_id');
    this.setState({ token, userId });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { token, userId } = this.state;
    return (
      <>
        <Route
          {...rest}
          render={props =>
            token ? (
              <div>
                <Component {...props} userId={userId} />
              </div>
            ) : (
              <Redirect to={{ pathname: '/Display' }} />
            )
          }
        />
      </>
    );
  }
}

PrivateRoute = withRouter(PrivateRoute);

const Routes = () => (
  <>
    <Router>
      <Header />
      <Switch>
        <Route path="/display" component={Display} exact />
        <Route path="/login" component={Login} exact />
        <PrivateRoute path="/" component={Home} exact />
        <PrivateRoute path="/edit" component={Edit} exact />
      </Switch>
    </Router>
  </>
);

export default Routes;
