import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from './global';
import 'react-table/react-table.css';
import Router from './router/Router';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 10px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    background-color: ${props => props.theme.mainBackground};
  }
  .ReactTable {
    ${'' /* border-radius: 1em; */}
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  .ReactTable .rt-thead {
    overflow-y: scroll;
  }
  .ReactTable .rt-thead.-headerGroups {
    display: none;
  }
  .center-table-content {
    text-align: center;
  }
  .-sort-desc {
    box-shadow: none !important;
    ${'' /* &:before {
      content: "▼";
      float: right;
    } */}
  }

  .-sort-asc {
    box-shadow: none !important;
    ${'' /* &:before {
      content: "▲";
      float: right;
    } */}
  }
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <Router />
    <GlobalStyle />
  </ThemeProvider>
);

export default App;
