import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  padding: 1em 2em;
  :focus {
    outline: 0;
  }
  &.submit {
    font-size: 1em;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.titleColor};
    border: none;
    &:disabled {
      opacity: 0.4;
      cursor: arrow;
    }
  }
  &.add {
    font-size: 1em;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.titleColor};
    border: none;
    border-radius: 4px;
    &:disabled {
      opacity: 0.4;
      cursor: arrow;
    }
  }
`;

const Button = ({ type, className, title, onClick }) => (
  <ButtonContainer type={type} className={className} onClick={onClick}>
    {title}
  </ButtonContainer>
);

export default Button;
