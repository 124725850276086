import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Form from './Form';
import Button from './Button';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    overflow: 'none',
    background: 'none',
  },
};

const AddTenantContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 80%;
  min-width: 300px;
  height: 540px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px ${props => props.theme.black};
  background-color: ${props => props.theme.white};
  h2 {
    display: flex;
    justify-content: center;
    font-size: 2em;
    font-weight: lighter;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.black};
  }
  button {
    margin-top: 1em;
    cursor: pointer;
    min-width: 100%;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.red};
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1em;
    text-align: center;
  }
  button:focus {
    outline: 0;
  }
`;

const AddTenant = ({
  onAfterOpen,
  onRequestClose,
  contentLabel,
  handleChange,
  handleSubmit,
  state,
  loading,
  error,
}) => (
  <Modal
    isOpen={state.isModalOpen}
    onAfterOpen={onAfterOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    contentLabel={contentLabel}
    shouldCloseOnOverlayClick={false}
  >
    <AddTenantContainer>
      <h2>Add Tenant</h2>
      <Form method="post" onSubmit={handleSubmit}>
        <fieldset>
          <div className="name">
            <label htmlFor="name">
              Name
              <input
                type="text"
                name="name"
                placeholder="name"
                value={state.name}
                onChange={handleChange}
              />
              {/* {state.errors.name.length > 0 && <span className="error">{state.errors.name}</span>} */}
            </label>
          </div>

          <div className="floor">
            <label htmlFor="floor">
              Floor
              <input
                type="text"
                name="floor"
                placeholder="floor"
                value={state.floor}
                onChange={handleChange}
              />
              {state.errors.floor.length > 0 && <span className="error">{state.errors.floor}</span>}
            </label>
          </div>

          <div className="suite">
            <label htmlFor="suite">
              Suite
              <input
                type="text"
                name="suite"
                placeholder="suite"
                value={state.suite}
                onChange={handleChange}
              />
              {/* {state.errors.suite.length > 0 && <span className="error">{state.errors.suite}</span>} */}
            </label>
          </div>

          <div className="submit">
            <Button type="submit" className="submit" title={loading ? 'Submitting' : 'Submit'} />
          </div>
        </fieldset>
      </Form>
      <button onClick={() => onRequestClose()}>Cancel</button>
    </AddTenantContainer>
  </Modal>
);

export default AddTenant;
