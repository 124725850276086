const theme = {
  headerBackground: '#4E4E4E',
  mainBackground: '#3C3C3C',
  white: '#eeeeee',
  navItemColors: '#BC9768',
  titleColor: '#D26921',
  red: '#FF0000',
  black: '#222',
  lightBlack: '#444',
};

export default theme;
