import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import SIGNIN_MUTATION from '../mutations/signin';
import Button from '../components/Button';
import Form from '../components/Form';
import validEmail from '../utils/validEmail';
import validateForm from '../utils/validateForm';

const LoginContainer = styled.div`
  margin: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  margin-top: 10em;
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 80%;
  min-width: 300px;
  height: 425px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px ${props => props.theme.black};
  background-color: ${props => props.theme.white};
  h2 {
    display: flex;
    justify-content: center;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: ${props => props.theme.black};
  }
`;

const Login = props => {
  const [state, setState] = useState({
    email: 'test@test.com',
    password: 'secret',
    errors: {
      email: '',
      password: '',
    },
  });

  const [signin, { loading }] = useMutation(SIGNIN_MUTATION);

  const handleChange = e => {
    const { name, value } = e.target;
    const { errors } = state;
    switch (name) {
      case 'email':
        errors.email = validEmail.test(value) ? '' : 'Email is not valid!';
        break;
      case 'password':
        errors.password = value.length < 5 ? 'Password must be 5 characters long!' : '';
        break;
      default:
        break;
    }

    setState({ ...state, errors, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (validateForm(state.errors)) {
      const { data } = await signin({
        variables: { email: state.email, password: state.password },
      });

      localStorage.setItem('lobby_token', data.signin.token);
      localStorage.setItem('user_id', data.signin.id);
      props.history.push('/');
    } else {
      console.error('Invalid Form');
    }
  };

  return (
    <LoginContainer>
      <FormContainer>
        <h2>Login</h2>
        <Form method="post" onSubmit={handleSubmit}>
          <fieldset>
            <div className="email">
              <label htmlFor="email">
                Email
                <input
                  type="text"
                  name="email"
                  placeholder="email"
                  value={state.email}
                  onChange={handleChange}
                />
                {state.errors.email.length > 0 && (
                  <span className="error">{state.errors.email}</span>
                )}
              </label>
            </div>

            <div className="password">
              <label htmlFor="password">
                Password
                <input
                  type="password"
                  name="password"
                  placeholder="password"
                  value={state.password}
                  onChange={handleChange}
                />
                {state.errors.password.length > 0 && (
                  <span className="error">{state.errors.password}</span>
                )}
              </label>
            </div>

            <div className="submit">
              <Button type="submit" className="submit" title={loading ? 'Submitting' : 'Submit'} />
            </div>
          </fieldset>
        </Form>
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
