import React from 'react';

import WeatherIcon from 'react-icons-weather';

const Weather = ({ iconId, flip, rotate, temp }) => (
  <>
    <WeatherIcon
      name="owm"
      iconId={typeof iconId === 'undefined' ? '03d' : iconId}
      flip={flip || 'horizontal'}
      rotate={rotate || '90'}
    />
    <span>{temp.toString()}&#176;</span>
  </>
);

export default Weather;
