import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }
  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`;

const Form = styled.form`
  display: flex;
  label {
    margin-bottom: 1em;
    color: ${props => props.theme.lightBlack};
  }
  input,
  textarea,
  select {
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
    &:focus {
      outline: 0;
      border-color: ${props => props.theme.black};
    }
  }
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  button,
  input[type='submit'] {
    cursor: pointer;
    min-width: 100%;
    color: ${props => props.theme.white};
    border: none;
    border-radius: 4px;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.938em;
    text-align: center;
  }
  button:focus {
    outline: 0;
  }
  fieldset {
    width: 100%;
    border: 0;
    padding: 0;
    &[disabled] {
      opacity: 0.5;
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    }
  }
  .error {
    font-size: 1em;
    color: ${props => props.theme.red};
  }
  .name,
  .floor,
  .suite,
  .password,
  .email {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .submit {
    width: 100%;
  }
`;

export default Form;
