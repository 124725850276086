import gql from 'graphql-tag';

const DELETE_TENANT = gql`
  mutation deleteTenant($id: String!) {
    deleteTenant(id: $id) {
      id
    }
  }
`;

export default DELETE_TENANT;
