import gql from 'graphql-tag';

const SIGNIN_MUTATION = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      email
      name
      token
      company {
        id
        name
        buildings {
          id
          name
        }
      }
    }
  }
`;

export default SIGNIN_MUTATION;
