export default async function() {
  const id = '5097677';
  const apiKey = process.env.REACT_APP_WEATHER_API;
  const response = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?id=${id}&appid=${apiKey}`
  );

  const results = await response.json();

  const temp = Math.floor((results.main.temp - 273.15) * 1.8 + 32);

  return { temp, ...results.weather[0] };
}
