import gql from 'graphql-tag';

const BUILDING = gql`
  query building($id: String!) {
    building(id: $id) {
      id
      name
      tenants {
        id
        name
        floor
        suite
      }
    }
  }
`;

export default BUILDING;
