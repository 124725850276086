import gql from 'graphql-tag';

const UPDATE_TENANT = gql`
  mutation updateTenant($id: String!, $floor: String, $name: String, $suite: String) {
    updateTenant(id: $id, floor: $floor, name: $name, suite: $suite) {
      id
      floor
      name
      suite
    }
  }
`;

export default UPDATE_TENANT;
