import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import getWeather from '../utils/getWeather';
import Weather from './Weather';

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.headerBackground};
  padding: 0.5em 1em;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    color: ${props => props.theme.navItemColors};
    font-size: 2em;
    margin-bottom: 10px;
    @media (max-width: 1065px) {
      font-size: 1em;
    }
  }
  li {
    padding: 0 15px;
  }
  a {
    text-decoration: none;
  }
  h1 {
    font-size: 3.5em;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.titleColor};
    @media (max-width: 1065px) {
      font-size: 2em;
    }
  }
  button {
    color: ${props => props.theme.navItemColors};
    font-size: 1em;
    padding: 0 15px;
    background: none;
    border: none;
  }
  button:focus {
    outline: 0;
  }
`;

const Header = props => {
  const [weather, setWeather] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const token = localStorage.getItem('lobby_token');

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      try {
        const resp = await getWeather();

        setWeather(resp);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isError) {
    console.log('Weather Fetch Error');
  }

  const handleLogout = () => {
    localStorage.removeItem('lobby_token');
    props.history.push('/display');
  };

  const hasToken = props.location.pathname === '/' ? '/display' : '/';

  const noToken = props.location.pathname === '/login' ? '/display' : '/login';

  return (
    <HeaderContainer>
      <div className="title">
        <Link to={token ? hasToken : noToken}>
          <h1>Parker Plaza</h1>
        </Link>
      </div>
      <ul>
        <li>{!isLoading && <Weather iconId={weather.id.toString()} temp={weather.temp} />}</li>
        <li>{moment().format('h:mm a')}</li>
        <li>{moment().format('MMMM D, YYYY')}</li>
        {token && (
          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        )}
      </ul>
    </HeaderContainer>
  );
};

export default withRouter(Header);
