const empty = state => {
  let valid = true;

  const check = Object.keys(state).reduce((acc, key) => {
    switch (key) {
      case 'floor':
        return { ...acc, [key]: state[key] };
      case 'name':
        return { ...acc, [key]: state[key] };
      case 'suite':
        return { ...acc, [key]: state[key] };
      default:
        return acc;
    }
  }, {});

  Object.values(check).forEach(
    // if we have an error string set valid to false
    val => val.length > 0 && (valid = false)
  );
  return valid;
};

export default empty;
