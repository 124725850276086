import gql from 'graphql-tag';

const TENANT = gql`
  query tenant($id: String!) {
    tenant(id: $id) {
      id
      name
      floor
      suite
    }
  }
`;

export default TENANT;
