import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import USER from '../queries/user';
import CREATE_TENANT from '../mutations/createTenant';
import Button from '../components/Button';
import AddTenant from '../components/AddTenant';
import Tenants from '../components/Tenants';
import validateForm from '../utils/validateForm';
import empty from '../utils/notEmpty';

const HomeContainer = styled.div`
  margin: 1em 2em 0 0;
  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .table-container {
    padding: 2em;
  }
`;

const Home = props => {
  const [state, setState] = useState({
    isModalOpen: false,
    name: '',
    floor: '',
    suite: '',
    errors: {
      name: '',
      floor: '',
      suite: '',
    },
  });

  const { data, loading, error } = useQuery(USER, {
    variables: { id: props.userId },
  });

  const [createTenant, { loading: loadingTwo, error: errorTwo }] = useMutation(CREATE_TENANT, {
    refetchQueries: [{ query: USER, variables: { id: props.userId } }],
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  const handleModal = () => {
    setState({
      ...state,
      isModalOpen: !state.isModalOpen,
      name: '',
      floor: '',
      suite: '',
      errors: {
        floor: '',
      },
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const { errors } = state;
    switch (name) {
      case 'floor':
        errors.floor = value.length > 3 ? 'Floor # cannot be more than 3 characters long!' : '';
        break;
      default:
        break;
    }

    setState({ ...state, errors, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (validateForm(state.errors) && !empty(state)) {
      const response = await createTenant({
        variables: {
          buildingId: data.user.company.buildings[0].id,
          name: state.name,
          floor: state.floor,
          suite: state.suite,
        },
      });

      if (response) {
        setState({
          isModalOpen: false,
          name: '',
          floor: '',
          suite: '',
          errors: {
            name: '',
            floor: '',
            suite: '',
          },
        });
      }
    } else {
      console.log('SEND ERROR');
    }
  };

  return (
    <HomeContainer>
      <div className="button-container">
        <Button className="add" title="Add Tenant" onClick={handleModal}>
          Trigger Modal
        </Button>
      </div>

      <AddTenant
        className="modal"
        isOpen={state.isModalOpen}
        onRequestClose={handleModal}
        contentLabel="TEST"
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        state={state}
        loading={loadingTwo}
        error={errorTwo}
      />
      <div className="table-container">
        {data.user.company.buildings[0].tenants.length === 0 ? (
          <p>Plese Add A Tenant</p>
        ) : (
          <Tenants
            history={props.history}
            loading={loading}
            tenants={data.user.company.buildings[0].tenants}
          />
        )}
      </div>
    </HomeContainer>
  );
};

export default Home;
