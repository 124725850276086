import gql from 'graphql-tag';

const USER = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      name
      company {
        id
        name
        buildings {
          id
          name
          tenants {
            id
            name
            floor
            suite
          }
        }
      }
    }
  }
`;

export default USER;
