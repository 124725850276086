import gql from 'graphql-tag';

const CREATE_TENANT = gql`
  mutation createTenant($buildingId: String!, $floor: String!, $name: String, $suite: String) {
    createTenant(buildingId: $buildingId, floor: $floor, name: $name, suite: $suite) {
      id
      floor
      name
      suite
    }
  }
`;

export default CREATE_TENANT;
